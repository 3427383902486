import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

const Service = ({ closeModal, modalData, setModalData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const { error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const {setIsLoader} = useLoader();
  const apiBaseUrl = window.API_CONFIG.All_Api.faqs;
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    page: "",
    code: generateUUID(),
    question: [""],
    answers: [""],
  });
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/
  const handleInputChange = (event, field, index) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => {
        if (field === "question") {
          const updatedQuestions = {
            ...prevModalData[field],
            [index]: value,
          };
          return {
            ...prevModalData,
            [field]: updatedQuestions,
          };
        } else if (field === "answers") {
          const updatedAnswers = {
            ...prevModalData[field],
            [index]: value,
          };
          return {
            ...prevModalData,
            [field]: updatedAnswers,
          };
        } else {
          return {
            ...prevModalData,
            [field]: value,
          };
        }
      });
    } else {
      setCase((prevAddCase) => {
        if (field === "question") {
          const updatedQuestions = {
            ...prevAddCase[field],
            [index]: value,
          };
          return {
            ...prevAddCase,
            [field]: updatedQuestions,
          };
        } else if (field === "answers") {
          const updatedAnswers = {
            ...prevAddCase[field],
            [index]: value,
          };
          return {
            ...prevAddCase,
            [field]: updatedAnswers,
          };
        } else {
          return {
            ...prevAddCase,
            [field]: value,
          };
        }
      });
    }
  };

  const handleClear = () => {
    const clearfields = {
      page: "",
      question: [""],
      answers: [""],
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["page", "question"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };
  const answersObject = modalData ? modalData.answers : addCase.answers;

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([byteArray], { type: mimeType });
  };

  const processImage = async (img) => {
    const src = img.getAttribute("src");
    if (src && src.startsWith("data:image/")) {
      const parts = src.split(";");
      const mimeType = parts[0].split(":")[1];
      const imageData = parts[1].split(",")[1];
      const blob = base64ToBlob(imageData, mimeType);
      const file = new File([blob], "image.png", { type: mimeType });

      const formData = new FormData();
      formData.append("upload", file);

      try {
        const response = await fetch(window.API_CONFIG.imageStringUrl, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          img.setAttribute("src", data.url);
        } else {
          console.error("Error uploading image:", response.statusText);
        }
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const processImagesSequentially = async () => {
    for (const key in answersObject) {
      if (answersObject.hasOwnProperty(key)) {
        const answerHTML = answersObject[key];
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = answerHTML;

        const imgElements = Array.from(tempDiv.querySelectorAll("img"));
        await Promise.all(imgElements.map(processImage));

        // Convert the processed HTML content back to a string
        answersObject[key] = tempDiv.innerHTML;
      }
    }

    if (modalData) {
      modalData.answers = answersObject;
    } else {
      addCase.answers = answersObject;
    }
  };

  // Call the function to start processing answers
  processImagesSequentially();

  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true);

    try {
      if (modalData) {
        await processImagesSequentially();
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const duplicateItem = careerData.find(
            (item) =>
              item.page === modalData.page && item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );

          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            faqs: updatedCareerData,
          }));

          closeModal();
          notify.success(updateResponse.data.message);
          setCase({}); 
        }
        else{
          notify.error(updateResponse.data.message);
        }
      }
         catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        await processImagesSequentially();
        // Adding a new data entry
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.page === addCase.page
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }
          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            faqs: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false)
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit FAQS" : "Add New FAQS"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
