import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import Service from "./Service";
import ViewForm from "./ViewForm";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import DeleteBox from "../DeleteBox";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

function ProductAPi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, setData, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const{setIsLoader} = useLoader();
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const token = getToken();
  const apiBaseUrl = window.API_CONFIG.All_Api.ipmapping;

  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);

  useEffect(() => {
    try {
      if (!data.ipmapping || data.ipmapping.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.ipmapping.filter((item) => {
        const name = item?.ip_type_name ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>
          a.ip_type_name.localeCompare(b.ip_type_name) ||
          new Date(a.updatedDate) - new Date(b.updatedDate)
      );

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.ipmapping, searchQuery, page, pageSize, totalLength]);
  /* Handle Remove function */

  const closeDelModal = () => setShowDeleteModal(false);

  const performDelete = async () => {
    closeDelModal(); 
    setIsLoader(true)

    try {
      const updatedCareerData = data.ipmapping.filter(
        (item) => item.code !== deleteCandidate.code
      );

      const response = await axios.post(
        apiBaseUrl,
        { ViewModels: updatedCareerData },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": token,
          },
        }
      );
      console.log(response);
      setData((prevData) => ({
        ...prevData,
        ipmapping: updatedCareerData,
      }));

      notify.success("Data removed successfully");
    } catch (error) {
      console.error("Error removing data:", error);
      notify.error(error.message);
    }
    setIsLoader(false)
  };

  const handleRemove = (dataToRemove) => {
    setShowDeleteModal(true);
    setDeleteCandidate(dataToRemove);
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const items = filteredBlog
    .slice(startIndex, endIndex)
    .map((viewModel, index) => {
      const id = `item-${index}`;
      const fetchData = async () => {
        try {
          setModalData(viewModel);
        } catch (error) {
          console.error(error);
        }
      };
      return (
        <tr title="View" key={id}>
          <td
            onClick={() => {
              setShowModal(true);
              fetchData();
            }}
          >
            <FaEye />
          </td>
          <td
            title="Edit"
            onClick={() => {
              setEditModal(true);
              fetchData();
            }}
          >
            <FaEdit />
          </td>
          <td title="Delete" onClick={() => handleRemove(viewModel)}>
            <FaTrashAlt />
          </td>
          <td width="25%">
            {viewModel && viewModel.ip_type_name
              ? viewModel.ip_type_name.substring(0, 20)
              : "Name not available"}
          </td>
          <td className="category-name" width="33%">
  {viewModel.mappingcategoryname && viewModel.mappingcategoryname.length > 0 ? (
    viewModel.mappingcategoryname.map((item, index) => {
      const firstWord = item.data?.split(" ")[0];

      // Check if the item has data
      if (item.data) {
        return (
          <tr key={index}>
            <td>
              <strong>{item.name + ":"}</strong>
            </td>
            <td className="category-data">{firstWord + "..."}</td>
          </tr>
        );
      } else {
        return null;
      }
    })
  ) : (
   "Not found"
  )}
</td>

          <td width="16%">
            {new Date(viewModel.updatedDate || viewModel.createdDate)
              .toISOString()
              .slice(0, 10)}
          </td>
          <td width="10%">
            <span
              className={`status ${viewModel.status ? "active" : "inactive"}`}
            >
              {viewModel.status ? "active" : "inactive"}
            </span>
          </td>
          {showModal && (
            <ViewForm Modalclose={closeModal} modalData={modalData} />
          )}
          {editModal && (
            <Service
              closeModal={modalClose}
              modalData={modalData}
              setModalData={setModalData}
              setFilteredBlog={setFilteredBlog}
            />
          )}
        </tr>
      );
    });

  return (
    <tbody>
     {filteredBlog.length === 0 ? (
          <tr>
          <td classname ="noFound" colSpan="12">No data found</td>
          </tr>
        ) : (
          items
        )}
      {showDeleteModal && (
        <DeleteBox Modalclose={closeDelModal} handleDelete={performDelete} />
      )}
    </tbody>
  );
}
export const InterestedproductLength = () => {
  const { data } = useContext(ApiContext);
  return data.interestedproduct.length;
};
export default ProductAPi;
