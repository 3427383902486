import React, { useState, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { imageUpload } from "../ImageUpload";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

export default function Service({ closeModal, modalData, setModalData }) {
  const [formError, setFormError] = useState({});
  const { error, setData } = useContext(ApiContext);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const {setIsLoader} = useLoader();
  const [file, setFile] = useState(null);
  const apiBaseUrl = window.API_CONFIG.All_Api.pagevideo;
  const token = getToken();
  const imgDir = "page-images";

  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    code: generateUUID(),
    title: "",
    page: "",
    embedurl: "",
    thumbnail_image: "",
  });
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };

  /* -----handleImage Upload function ----*/

  const handleImageUpload = async (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 100 * 1024;
    if (selectedFile.size > fileSizeLimit) {
      notify.error("File is too big");
      return;
    } 
    try {
      const base64Data = await convertBase64(selectedFile);
      setThumbnailImage(base64Data);
      setFile(selectedFile);
      if (modalData) {
        setModalData({
          ...modalData,
          thumbnail_image_name: selectedFile.name,
          thumbnail_image: base64Data,
        });
      } else {
        setCase({
          ...addCase,
          thumbnail_image_name: selectedFile.name,
          thumbnail_image: base64Data,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle error while converting to base64
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["title", "page", "embedurl"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  const handleClear = () => {
    const clearfields = {
      title: "",
      page: "",
      description: "",
      thumbnail_image_name: "",
      thumbnail_image:"",
      file: null,
      embedurl: "",
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true)

    try {
      if (modalData) {
        const abc = await imageUpload(
          modalData.title,
          modalData.thumbnail_image,
          imgDir,
          token
        );
        modalData.thumbnail_image = abc;
        // Updating an existing data entry
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );
          const duplicateItem = careerData.find(
            (item) =>
              item.title === modalData.title && item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            pagevideo: updatedCareerData,
          }));

          closeModal();
          notify.success(updateResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(updateResponse.data.message);
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        const abc = await imageUpload(
          addCase.title,
          addCase.thumbnail_image,
          imgDir,
          token
        );
        addCase.thumbnail_image = abc;
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.title === addCase.title
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }
          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            pagevideo: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);
          setCase({}); 
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false)
    }
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Page Video" : "Add Page Video"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleImageUpload={handleImageUpload}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
                thumbnail_image_name={getInputValue("thumbnail_image_name")}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
}
