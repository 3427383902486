import React, { useState, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AddForm from "./AddForm";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

const RedirectServ = ({ closeModal, modalData, setModalData }) => {
  const { error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const apiBaseUrl = window.API_CONFIG.All_Api.urllist;
  const {setIsLoader} = useLoader();
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const char = Math.floor(Math.random() * characters.length);
      result += characters.charAt(char);
    }
    return result;
  };

  const generateCustomURL = () => {
    const inputField = modalData ? modalData.tourl : addCase.tourl;
    if (inputField === "") {
      alert("Please enter the `Redirect To Url`");
      return;
    }

    const urlObject = new URL(inputField);
    const domain = `${urlObject.protocol}//${urlObject.hostname}/`;
    const generatedUrl = domain + generateRandomString(8);

    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        fromurl: generatedUrl,
      }));
    } else {
      setCase((prevAddCase) => ({ ...prevAddCase, fromurl: generatedUrl }));
    }
  };

  const [addCase, setCase] = useState({
    fromurl: "",
    tourl: "",
    code: generateUUID(),
  });

  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };

  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["fromurl", "tourl"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `Redirect ${
          field.charAt(0).toUpperCase() + field.slice(1)
        }  is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  const handleClear = () => {
    const clearfields = {
      fromurl: "",
      tourl: "",
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true);

    try {
      if (modalData) {
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );
          const duplicateItem = careerData.find(
            (item) =>
              item.fromurl === modalData.fromurl && item.code !== modalData.code ||
            item.tourl === modalData.tourl && item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          if (modalData.fromurl === modalData.tourl){
            notify.error("From URL and To URL cannot be the same.");
            return;
          }
          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );
          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            urllist: updatedCareerData,
          }));
          notify.success(updateResponse.data.message);
          closeModal();

          setCase({}); 
        }
        else{
          notify.error(updateResponse.data.message);
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error("Error occurred. Please try again.");
        }
      } else {
        // Adding a new data entry
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) =>
              item.fromurl === addCase.fromurl ||
            item.tourl === addCase.tourl 
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          if (addCase.fromurl === addCase.tourl){
            notify.error("From URL and To URL cannot be the same.");
            return;
          }
          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log( addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            urllist: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false)
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper" onClick={closeModal}></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Redirect Url" : "Add New Redirect Url"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
                generateCustomURL={generateCustomURL}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RedirectServ;
