import React from "react";

const EditorConfig = {
  uploader: {
    insertImageAsBase64URI: true,
  },
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",

    "paragraph",
    "classSpan",
    "lineHeight",    
    "|",
    "subscript",
    "superscript",
    "image",
    "video",
    "|",
    "spellcheck",
    "|",
    "cut",
    "copy",
    "paste",
    "selectall",
    "copyformat",
    "|",
    "hr",
    "table",
    "link",
    "symbols",
    "|",
    "outdent",
    "indent",
    "align",
  
    "|",
    "brush",
    "|",
    "undo",
    "redo",
    "|",
    "find",
    "|",
    "fullsize",
    "preview",
    "print",
    "about",
  ],
  buttonsXS: [
    "bold",
    "image",
    "|",
    "brush",
    "paragraph",
    "|",
    "align",
    "|",
    "undo",
    "redo",
    "|",
    "eraser",
    "dots",
  ],
};

export default EditorConfig;
