import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { imageUpload } from "../ImageUpload";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";

const Service = ({ closeModal, modalData, setModalData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const { data, error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const {setIsLoader} = useLoader();
  const apiBaseUrl = window.API_CONFIG.All_Api.blog;
  const token = getToken();
  const fieldsArray = ["description"];
  const parsedData = data.blog.map((item) => JSON.parse(item));
  const imgDir = "blog-images";
  const [addCase, setCase] = useState({
    name: "",
    url: "",
    author: "Admin",
    authorBio: "",
    status: "",
    imgalttext: "",
    category: "",
    categoryId: [],
    country: "",
    countryId: [],
    language: "",
    languageId: [],
    thumbnail_image: "",
  });

  /*---- get all the input field ----*/
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/
  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };

  /* -----handleImage Upload function ----*/

  const handleImageUpload = async (event, field) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 100 * 1024;
    if (selectedFile.size > fileSizeLimit) {
      notify.error("File is too big");
      return;
    }
    try {
      const base64Data = await convertBase64(selectedFile);
      if (modalData) {
        setModalData({
          ...modalData,
          thumbnail_image_name: selectedFile.name,
          thumbnail_image: base64Data,
        });
      } else {
        setCase({
          ...addCase,
          thumbnail_image_name: selectedFile.name,
          thumbnail_image: base64Data,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle error while converting to base64
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  /*---- handle clear function----*/
  const handleClear = () => {
    const clearfields = {
      url: "",
      categoryId: [],
      countryId: [],
      languageId: [],
      name: "",
      status: false,
      imgalttext: "",
      thumbnail_image_name: "",
      author: "",
      authorBio: "",
      description: "",
      file: null,
      id: null,
      category: "",
    };
    if (!modalData) {
      setCase([clearfields]);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };
  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["name", "url", "imagetext", "thumbnail_image"];
    const validatefield = (field) => {
      if (!modalData) {
        if (addCase && addCase[field] === "") {
          errors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      } else {
        if (modalData[field] === "") {
          errors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      }
      if (
        (!modalData || modalData.thumbnail_image === "") &&
        (!addCase || addCase.thumbnail_image === "")
      ) {
        errors.thumbnail_image = "Please select a file.";
      }
  
    };

    fieldarr.forEach(validatefield);

    return errors;
  };
  //Description image process
  async function processAndUploadImages(modalData, addCase, fieldsArray) {
    for (const field of fieldsArray) {
      const htmlContent = modalData ? modalData[field] : addCase[field];
      if (!htmlContent) {
        // Handle the case where htmlContent is undefined or empty
        continue; // Skip to the next field
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const imgElements = Array.from(tempDiv.querySelectorAll("img"));

      const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArray], { type: mimeType });
      };

      const processImage = async (img) => {
        const src = img.getAttribute("src");
        if (src && src.startsWith("data:image/")) {
          const parts = src.split(";");
          const mimeType = parts[0].split(":")[1];
          const imageData = parts[1].split(",")[1];
          const blob = base64ToBlob(imageData, mimeType);
          const file = new File([blob], "image.png", { type: mimeType });

          const formData = new FormData();
          formData.append("upload", file);
          formData.append(
            "ckCsrfToken",
            "2fDpPNU1D4C9z19x1f0XZTAaH6nZlR8DoiH950V5"
          );

          try {
            const response = await fetch(window.API_CONFIG.imageStringUrl, {
              method: "POST",
              body: formData,
            });

            if (response.ok) {
              const data = await response.json();
              const imgUrl = data.url;
              img.setAttribute("src", imgUrl);
            } else {
              console.error("Error uploading image:", response.statusText);
            }
          } catch (error) {
            console.error("Error processing image:", error);
          }
        }
      };

      const processImagesSequentially = async () => {
        for (const img of imgElements) {
          await processImage(img);
        }
        if (modalData) {
          modalData[field] = tempDiv.innerHTML;
        } else {
          addCase[field] = tempDiv.innerHTML;
        }
      };

      await processImagesSequentially();
    }
  }

  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();
    if (Object.keys(formErrors).length > 0) {
      setFormError({ ...formErrors });
      notify.warning();
      return;
    }
  
    setFormError("");
    setIsLoader(true);
  
    try {
      if (modalData) {
        await processAndUploadImages(modalData, null, fieldsArray);
  
        const abc = await imageUpload(
          modalData.name,
          modalData.thumbnail_image,
          imgDir,
          token
        );
        modalData.thumbnail_image = abc;
  
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };
  
        const duplicateItem = parsedData.find(
          (item) => item.name === modalData.name && item.id !== modalData.id
        );
  
        if (duplicateItem) {
          notify.error("Data is a duplicate and cannot be updated.");
          return;
        }
  
        const response = await axios.post(apiBaseUrl, updatedData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": token,
          },
        });
  
        if (response.data.success === true) {
          const updatedDataResponse = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });
  
          const updatedDataFromServer = updatedDataResponse.data?.data || [];
          setData((prevData) => ({
            ...prevData,
            blog: updatedDataFromServer,
          }));
  
          closeModal();
          notify.success(response.data.message);
          setCase({});
        } else {
          notify.error(response.data.message);
        }
      } else {
        await processAndUploadImages(null, addCase, fieldsArray);
  
        const abc = await imageUpload(
          addCase.name,
          addCase.thumbnail_image,
          imgDir,
          token
        );
        addCase.thumbnail_image = abc;
  
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };
  
        const duplicateItem = parsedData.find(
          (item) => item.name === addCase.name
        );
  
        if (duplicateItem) {
          notify.error("Data is a duplicate and cannot be updated.");
          return;
        }
  
        const response = await axios.post(apiBaseUrl, updatedBlogData, {
          headers: {
            Accept: "application/json",
            "x-access-token": token,
          },
        });
  
        if (response.data.success === true) {
          const updatedBlogResponse = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });
  
          const updatedDataFromServer = updatedBlogResponse.data?.data || [];
          setData((prevData) => ({
            ...prevData,
            blog: updatedDataFromServer,
          }));
  
          closeModal();
          notify.success(response.data.message);
          setCase({});
        } else {
          notify.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    } finally {
      setIsLoader(false);
    }
  };
  

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Blog" : "Add New Blog"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleImageUpload={handleImageUpload}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                postCategory={data.postCategory}
                language={data.language}
                country={data.country}
                closeModal={closeModal}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
