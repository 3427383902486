import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import Service from "./TestiService";
import ViewTesti from "./ViewTestimonial";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import DeleteBox from "../DeleteBox";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";

function TestiAPi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, setData, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const token = getToken();
  const {setIsLoader} = useLoader();
  const [modalData, setModalData] = useState(null);
  const apiBaseUrl = window.API_CONFIG.All_Api.testimonials;

  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);

  useEffect(() => {
    try {
      if (!data.testimonials || data.testimonials.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.testimonials.filter((item) => {
        const name = item?.title ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>
          a.title.localeCompare(b.title) ||
          new Date(a.updatedDate) - new Date(b.updatedDate)
      );

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.testimonials, searchQuery, page, pageSize, totalLength]);

  /* Handle Remove function */

  const closeDelModal = () => setShowDeleteModal(false);

  const performDelete = async () => {
    closeDelModal();
    setIsLoader(true)

    try {
      const updatedCareerData = data.testimonials.filter(
        (item) => item.code !== deleteCandidate.code
      );

      const response = await axios.post(
        apiBaseUrl,
        { ViewModels: updatedCareerData },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": token,
          },
        }
      );
      console.log(response);
      setData((prevData) => ({
        ...prevData,
        testimonials: updatedCareerData,
      }));

      notify.success("Data removed successfully");
    } catch (error) {
      console.error("Error removing data:", error);
      notify.error("Error removing data");
    }
    setIsLoader(false)
  };

  const handleRemove = (dataToRemove) => {
    setShowDeleteModal(true);
    setDeleteCandidate(dataToRemove);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const items = filteredBlog
    .slice(startIndex, endIndex)
    .map((viewModel, index) => {
      const id = `item-${index}`;
      const fetchData = async () => {
        try {
          setModalData(viewModel);
        } catch (error) {
          console.error(error);
        }
      };
      return (
        <tr key={id}>
          <td
            title="View"
            onClick={() => {
              setShowModal(true);
              fetchData();
            }}
          >
            <FaEye />
          </td>
          <td
            title="Edit"
            onClick={() => {
              setEditModal(true);
              fetchData();
            }}
          >
            <FaEdit />
          </td>
          <td title="Delete" onClick={() => handleRemove(viewModel)}>
            <FaTrashAlt />
          </td>

          <td title ={viewModel.title} width="25%">
            {viewModel && viewModel.title
              ? `${viewModel.title.substring(0, 20)}...`
              : "Title not available"}
          </td>

          <td width="38%">
            {viewModel.description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    viewModel.description.length > 100
                      ? viewModel.description.substring(0, 100) + "..."
                      : viewModel.description,
                }}
              />
            ) : (
              "Description not available"
            )}
          </td>
          <td width="16%">
            {new Date(viewModel.updatedDate || viewModel.createdDate)
              .toISOString()
              .slice(0, 10)}
          </td>
          <td width="10%">
            <span
              className={`status ${viewModel.status ? "active" : "inactive"}`}
            >
              {viewModel.status ? "active" : "inactive"}
            </span>
          </td>
          {showModal && (
            <ViewTesti Modalclose={closeModal} modalData={modalData} />
          )}
          {editModal && (
            <Service
              closeModal={modalClose}
              modalData={modalData}
              setModalData={setModalData}
              setFilteredBlog={setFilteredBlog}
            />
          )}
        </tr>
      );
    });

  return (
    <tbody>
      {filteredBlog.length === 0 ? (
        <tr>
          <td classname="noFound" colSpan="12">
            No data found
          </td>
        </tr>
      ) : (
        items
      )}
      {showDeleteModal && (
        <DeleteBox Modalclose={closeDelModal} handleDelete={performDelete} />
      )}
    </tbody>
  );
}
export const TestiLength = () => {
  const { data } = useContext(ApiContext);
  return data.testimonials.length;
};
export default TestiAPi;
