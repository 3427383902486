import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";

import { ApiContext } from "../apiContext";
import Select from "react-select";
import EditorConfig from "../../helper/JoditEditor";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  country,
  maileventtype,
}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const [selectedmaileventtypes, setSelectedmaileventtypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-12">
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={getInputValue("name")}
              onChange={(event) => handleInputChange(event, "name")}
            />
            <span className="non-valid">{formError.name}</span>
          </div>
  
        <div className="my-3 col-md-12">
            <Label htmlFor="description">Description</Label>
            <textarea
              type="text"
              id="description"
              name="description"
              placeholder="Description"
              value={getInputValue("description")}
              onChange={(event) => handleInputChange(event, "description")}
            />
            <span className="non-valid">{formError.description}</span>
          </div>
  
          <div className="my-3 col-md-12">
            <Label htmlFor="country">Country</Label>
            <Select
              name="country"
              options={
                country &&
                country
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code, // Use option.Code for value
                    label: option.Name, // Use option.Name for label
                  }))
              }
              value={
                !modalData
                  ? selectedCountry
                  : modalData?.countryId &&
                    modalData.countryId.map((countryId) => ({
                      value: countryId,
                      label: country.find((option) => option.Code === countryId)
                        ?.Name,
                    }))
              }
              onChange={(selectedCountry) => {
                if (!modalData) {
                  setSelectedCountry(selectedCountry);
                  setCase((prevData) => ({
                    ...prevData,
                    countryId: selectedCountry.map((option) => option.value),
                    country: selectedCountry
                      .map(
                        (option) =>
                          country.find(
                            (country) => country.Code === option.value
                          )?.Name
                      )
                      .join(", "),
                  }));
                } else {
                  const selectedValues = selectedCountry.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "country"
                  );
                  setModalData((prevData) => ({
                    ...prevData,

                    countryId: selectedCountry.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="category">maileventtype</Label>
            <Select
              name="category"
              options={
                maileventtype &&
                maileventtype
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code,
                    label: option.Name,
                  }))
              }
              value={
                !modalData
                  ? selectedmaileventtypes
                  : modalData?.maileventtypeId &&
                    modalData.maileventtypeId.map((maileventtypeId) => ({
                      value: maileventtypeId,
                      label: maileventtype.find(
                        (option) => option.Code === maileventtypeId
                      )?.Name,
                    }))
              }
              onChange={(selectedmaileventtypes) => {
                if (!modalData) {
                  setSelectedmaileventtypes(selectedmaileventtypes);
                  setCase((prevData) => ({
                    ...prevData,
                    maileventtype: selectedmaileventtypes
                      .map(
                        (option) =>
                          maileventtype.find(
                            (maileventtype) => maileventtype.Code === option.value
                          )?.Name
                      )
                      .join(", "),
                    maileventtypeId: selectedmaileventtypes.map((option) => option.value),
                  }));
                } else {
                  const selectedValues = selectedmaileventtypes.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "maileventtype"
                  );
                  setModalData((prevData) => ({
                    ...prevData,
                    maileventtypeId: selectedmaileventtypes.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
          </div>

        

         <div className="my-3 col-md-12">
            <Label for="body">Mail Template</Label>
            
            <JoditEditor
                  ref={editor}
                  config={editorConfig}
                  value={modalData?.body || ""}
                  onBlur={(newContent) =>
                    handleInputChange(
                      { target: { value: newContent } },
                      "body"
                    )
                  }
                  onChange={(newContent) => {}}
                />
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
