import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaRegWindowClose } from "react-icons/fa";
import { Header } from "../global/Header";
import { ViewModels } from "./SidebarFormat";

export default function FSideBar({ children }) {
  const [isopen, setOpen] = useState(false);
  const [sideshow, setSideShow] = useState(false);
  const location = useLocation();
  const toggle = () => {
    setOpen(!isopen);
  };

  useEffect(() => {
    // Check if children are present (routes other than "/" or specific paths)
    const pathsToHideSidebar = ["/", "/error"]; // Add any other paths you want to hide the sidebar on

    if (!pathsToHideSidebar.includes(location.pathname)) {
      setSideShow(true);
    } else {
      setSideShow(false);
    }
 if (location.pathname === "/") {
      localStorage.removeItem("data");
    }
  }, [location]);

  const isSubmenuActive = () => {
    return ViewModels.some((menu) =>
      menu.SubMenus.some((submenu) => location.pathname.includes(submenu.Href))
    );
  };

  return (
    <>
      <Header />
      {sideshow && (
        <div className="side">
          <div className="menu-bar">
            <div
              className={isopen ? "active side-menus" : "noactive side-menus"}
              style={{ width: isopen ? "380px" : "100px" }}
            >
              <div className="admin-block">
                <h2 style={{ display: isopen ? "block" : "none" }}>Admin</h2>
                <div className="bar">
                  {isopen ? (
                    <FaRegWindowClose onClick={toggle} />
                  ) : (
                    <FaBars onClick={toggle} />
                  )}
                </div>
              </div>

              {isSubmenuActive() ? (
                // Only render the submenu

                <div className="submenu-item">
                  {ViewModels.map((menu) =>
                    menu.SubMenus.map((submenu, subIndex) => (
                      <div className="menus-item" key={subIndex}>
                        <NavLink
                          to={submenu.Href}
                          className="link"
                          style={{
                            justifyContent: isopen ? "" : "center",
                          }}
                        >
                          <div className="link-icon">
                            <span>{submenu.Icon}</span>
                          </div>
                          <div
                            className="text-box"
                            style={{ display: isopen ? "block" : "none" }}
                          >
                            <p className="link-text">{submenu.Title}</p>
                          </div>
                        </NavLink>
                      </div>
                    ))
                  )}
                </div>
              ) : (
                // Render the main menus only
                <div className="main-menu">
                  {ViewModels.map((menu, index) => (
                    <div className="menus-item" key={index}>
                      <NavLink
                        to={menu.Href}
                        className="link"
                        key={index}
                        style={{
                          justifyContent: isopen ? "" : "center",
                        }}
                      >
                        <div className="link-icon">
                          <span>{menu.Icon}</span>
                        </div>
                        <div
                          className="text-box"
                          style={{ display: isopen ? "block" : "none" }}
                        >
                          <p className="link-text">{menu.Title}</p>
                        </div>
                      </NavLink>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <main>{children}</main>
          </div>
        </div>
      )}
      {!sideshow && <div className="main-content">{children}</div>}
    </>
  );
}
