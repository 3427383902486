import React, { useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import { ApiContext } from "../apiContext";

export default function AddForm({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  thumbnail_image_name,
  interestedproduct,
}) {
  const { error } = useContext(ApiContext);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="page">Page</Label>
            <Input
              type="text"
              id="url"
              name="url"
              placeholder="Url"
              value={getInputValue("url")}
              onChange={(event) => handleInputChange(event, "url")}
            />
            <span className="non-valid">{formError.page}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="title">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="metaDescription">Meta Description</Label>
            <textarea
              type="text"
              id="metaDescription"
              name="metaDescription"
              placeholder="Meta Description"
              value={getInputValue("metaDescription")}
              onChange={(event) => handleInputChange(event, "metaDescription")}
            />
            <span className="non-valid">{formError.metaDescription}</span>
          </div>

          <div className="my-3 col-md-12">
            <Label htmlFor="metaKeywords">Meta Keywords</Label>
            <textarea
              type="text"
              id="metaKeywords"
              name="metaKeywords"
              placeholder="Meta Keywords"
              value={getInputValue("metaKeywords")}
              onChange={(event) => handleInputChange(event, "metaKeywords")}
            />
            <span className="non-valid">{formError.metaKeywords}</span>
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="metaCanonical">Meta Canonical</Label>
            <textarea
              type="text"
              id="metaCanonical"
              name="metaCanonical"
              placeholder="Meta Canonical"
              value={getInputValue("metaCanonical")}
              onChange={(event) => handleInputChange(event, "metaCanonical")}
            />
            <span className="non-valid">{formError.metaCanonical}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="imagefile">
              Choose Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label for="interestedproduct">Category</Label>
            <select
              id="interestedproduct"
              name="interestedproduct"
              className="select-list"
              onChange={(event) => handleInputChange(event, "metaIproductname")}
              value={getInputValue("metaIproductname")}
            >
              <option value="">-- Select Category --</option>
              {interestedproduct &&
                interestedproduct
                  .filter((option) => option.Status === true)
                  .map((option) => (
                    <option key={option.Code} value={option.Name}>
                      {option.Name}
                    </option>
                  ))}
            </select>
            <span className="non-valid">{formError.metaIproductname}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
